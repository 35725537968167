import { useState, useEffect } from 'react';

export const useLocalStorage = (key, defaultValue, isEdited) => {
  const computeInitialState = () => {
    if (isEdited) {
      return defaultValue ?? JSON.parse(localStorage.getItem(key));
    } else {
      return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
    }
  };

  const [state, setState] = useState(computeInitialState());

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState];
};
